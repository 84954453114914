import React, { useState } from "react";
import { Button, Modal, Row, Col, FormControl } from "react-bootstrap";

export default function ({ onCreate }) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const resetState = () => {
    setShow(false);
    setName("");
  };
  const createPriceList = () => {
    if (name === "") {
      alert("Zadejte název cenníku");
      return;
    }
    let formData = new FormData();
    formData.append("name", name);
    fetch(`/api/priceLists/create.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        response.json().then((res) => {
          onCreate(res);
          resetState();
        });
      })
      .catch(console.log);
  };

  return (
    <>
      <Button variant="success" className="right" onClick={() => setShow(true)}>
        Vytvořit ceník
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-success text-white" closeButton>
          <Modal.Title>Vytvořit ceník</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} md={2}>
              Název
            </Col>
            <Col xs={12} md={10}>
              <FormControl
                type="text"
                placeholder="Název ceníku"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></FormControl>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetState}>
            Zrušit
          </Button>
          <Button variant="success" onClick={createPriceList}>
            Vytvořit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
