import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Calendar from "./Calendar";
import StorageKeys from "../../localStorageKeys";
import { Link } from "react-router-dom";
import { getProductPrice } from "../Order/PriceTable";
import { separateThousands, numToDayCz, numToDay, reformatNoDeliveryDate, dayToNum } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { calculateTotalPrice } from "../Order/Order";
import config from "../../Enviroment";

function getNewOrderMailBody(orderInfo, userInfo) {
  return `<html>
            <body>
              <p>
                V objednávkovém portálu byla vytvořena nová objednávka.
              </p>
              <h2>Informace o objednávce</h2>
              ${orderInfo}
              <br>
              <h2>Informace o zákazníkovi</h2>
              ${userInfo}
            </body>
          </html>`;
}

function getOrderConfirmBody(orderInfo) {
  return `<html>
            <body>
              <p>
                Děkujeme za Vaši objednávku. V případě nedostupnosti nějakého produktu Vás kontaktujeme a domluvíme se na dalším postupu.
              </p>
              <h2>Informace o objednávce:</h2>
              ${orderInfo}
            </body>
          </html>`;
}

function formatUserInformation(user) {
  return `<table>
            <tbody>
              <tr>
                <td><b>Jméno</b></td>
                <td>${user.Name}</td>
              </tr>
              <tr>
                <td><b>Email</b></td>
                <td>${user.Email}</td>
              </tr>
              <tr>
                <td><b>Název společnosti</b></td>
                <td>${user.CompanyName}</td>
              </tr>
              <tr>
                <td><b>Fakturační adresa</b></td>
                <td>${user.Street} ${user.HouseNum}, ${user.ZIP} ${user.City}</td>
              </tr>
              <tr>
                <td><b>Dodací adresa</b></td>
                <td><b>${user.DelStreet} ${user.DelHouseNum}, ${user.DelZIP} ${user.DelCity}</b></td>
              </tr>
              <tr>
                <td><b>IČO</b></td>
                <td>${user.CompanyId}</td>
              </tr>
              <tr>
                <td><b>DIČ</b></td>
                <td>${user.VAT}</td>
              </tr>
              <tr>
                <td><b>IČP</b></td>
                <td>${user.CompanyNumber}</td>
              </tr>
              <tr>
                <td><b>IČZ</b></td>
                <td>${user.PharId}</td>
              </tr>
              <tr>
                <td><b>Plátce DPH: </b></td>
                <td>${user.PayTax === 1 ? "Ano" : "Ne"}</td>
              </tr>
              <tr>
                <td colspan="2"><b>Otevírací doba</b></td>
              </tr>
              ${
                Object.keys(user.OppeningHours).map(k => 
                  `<tr>
                    <td><b>${numToDayCz(dayToNum(k))}</b></td>
                    <td>${user.OppeningHours[k].TimeFrom} - ${user.OppeningHours[k].TimeTo}</td>
                  </tr>`
                )
              }
             
            </tbody>
          </table>`;
}

function formatOrderInformaton(
  gift,
  formState,
  personalPickup,
  priceList,
  totalPrice,
  selectedOrderDays,
  user,
  totalAmmount,
  discountCodes
) {
  let res = `<h3>Objednané zboží:</h3>
            <table>
              ${document.getElementById("products").innerHTML}
            </table>`;
  if (gift !== undefined) {
    res += `<h3> K tomu navíc dostanete:</h3>
            ${gift.name} (+ ${gift.price})<br/>`;
  }
  if (discountCodes.length > 0) {
    res += `<h3>Aplikované slevové kódy</h3>
    ${discountCodes.map(
      (d) => `
      <p>
        ${d.Code}: ${d.Description}
      </p>`
    )}`;
  }
  if (formState.moreProducts !== "") {
    res += `<h3>Další objednané produkty</h3>
            <p>${formState.moreProducts}</p>`;
  }
  if (personalPickup) {
    res += "<h3> Zboží si vyzvednu osobně v lékárně na Selské.</h3>";
  } else {
    if (priceList.settings.showCalendar) {
      res += "<h3> Zboží můžu přijmout v následující dny:</h3><ul>";
      res += selectedOrderDays
        .sort((a, b) => a - b)
        .map(
          (sd) =>
            `<li>${numToDayCz(sd.getDay() - 1)} ${reformatNoDeliveryDate(sd)} ${
              user.OppeningHours[numToDay(sd.getDay() - 1)].TimeFrom
            }-${user.OppeningHours[numToDay(sd.getDay() - 1)].TimeTo}</li>`
        );
      res += "</ul>";
    }
    if (formState.deliveryNote !== "") {
      res += `<h3>Poznámka k datu dodání:</h3>
              <p>${formState.deliveryNote}</p>`;
    }
  }
  if (formState.note !== "") {
    res += `<h3>Poznámka</h3>
            <p>${formState.note}</p>`;
  }
  res += `<h3> Celková cena objednávky: ${separateThousands(totalPrice)} Kč</h3>`;
  res += `<h3> Celkový odběr: ${totalAmmount} Ks </h3>`;
  if (formState.tips !== "") {
    res += `<h3>Tipy na zlepšení:</h3>
            <p>${formState.tips}</p>`;
  }
  return res;
}

function getProduct(table, prodId) {
  return table.data.filter((d) => parseInt(d.id) === prodId)[0];
}

function getTable(tables, tableId) {
  return tables.filter((t) => parseInt(t.id) === tableId)[0];
}

function getTotalAmmount(ammounts) {
  const sum = (acc, val) => acc + val;
  return Object.values(ammounts)
    .map((vals) => Object.values(vals).reduce(sum))
    .reduce(sum);
}

function Products({ ammounts, tables, discountCodes }) {
  return (
    <table id="products">
      <thead>
        <tr>
          <th>Název</th>
          <th>Množství</th>
          <th>Cena za kus</th>
          <th>Celková cena</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(ammounts).map((tableId) => {
          return Object.keys(ammounts[tableId])
            .map((prodId) => {
              if (ammounts[tableId][prodId] > 0) {
                let table = getTable(tables, parseInt(tableId));
                let product = getProduct(table, parseInt(prodId));
                let price = getProductPrice(product, ammounts, table, discountCodes);
                return (
                  <tr key={product.id}>
                    <td>{product.name}</td>
                    <td>{ammounts[tableId][prodId]}</td>
                    <td>{separateThousands(price)} Kč</td>
                    <td>{separateThousands(price * ammounts[tableId][prodId])} Kč</td>
                  </tr>
                );
              }
              return undefined;
            })
            .filter((row) => row !== undefined);
        })}
      </tbody>
    </table>
  );
}

function sendEmailToAdmins(subject, body) {
  let formData = new FormData();
  formData.append("subject", subject);
  formData.append("body", body);
  fetch("/api/mails/sendEmailToAdmins.php", {
    method: "POST",
    body: formData,
  });
}

function sendEmailToUser(subject, body) {
  let formData = new FormData();
  formData.append("subject", subject);
  formData.append("body", body);
  fetch("/api/mails/sendEmailToLoggedUser.php", {
    method: "POST",
    body: formData,
  });
}

function sendEmail(to, subject, body) {
  let formData = new FormData();
  formData.append("subject", subject);
  formData.append("body", body);
  formData.append("email", to);
  fetch("/api/mails/sendEmail.php", {
    method: "POST",
    body: formData,
  });
}

export default function ({ history }) {
  const [selectedOrderDays, setSelectedDays] = useState(null);
  const [personalPickup, setPersonalPickup] = useState(false);
  const [formState, setFormState] = useState({
    mails: [""],
    moreProducts: "",
    note: "",
    tips: "",
    deliveryNote: "",
  });
  const updateSelectedDays = (val) => setSelectedDays(val);
  let sotredData = JSON.parse(sessionStorage.getItem(StorageKeys.OrderStateKey));
  const getTotalPrice = () => {
    let price = calculateTotalPrice(sotredData.priceList.tables, sotredData.ammounts, sotredData.discountCodes)[0];
    return separateThousands(
      sotredData.selectedGiftId !== -1 ? price + sotredData.priceList.gifts[sotredData.selectedGiftId].price : price
    );
  };

  const order = () => {
    fetch("/api/users/get.php")
      .then((res) => {
        res.json().then((userData) => {
          const userInfo = formatUserInformation(userData);
          const orderInfo = formatOrderInformaton(
            sotredData.priceList.gifts[sotredData.selectedGiftId],
            formState,
            personalPickup,
            sotredData.priceList,
            getTotalPrice(),
            selectedOrderDays,
            userData,
            getTotalAmmount(sotredData.ammounts),
            sotredData.discountCodes
          );
          const adminMail = getNewOrderMailBody(orderInfo, userInfo);
          const customerMail = getOrderConfirmBody(orderInfo);
          sendEmailToAdmins("Máte novou objednávku", adminMail);
          sendEmailToUser("Potvrzení objednávky v objednávkovém portálu lékaře", customerMail);
          formState.mails
            .filter((m) => m.length > 0)
            .forEach((m) => sendEmail(m, "Potvrzení objednávky v objednávkovém portálu lékaře", customerMail));
          alert("Objednávka byla odeslána");
          sessionStorage.clear();
          history.push("/Home");
        });
      })
      .catch(console.log);
  };
  if (sotredData === null)
    return (
      <p>
        Platnost stránky již vypršela, prosím vytvořte novou objednávku: <Link to="/">Vytvoření objednávky</Link>
      </p>
    );
  return (
    <Row>
      <Col xs={12} lg={6}>
        <div>
          <h3>Vybrali jste si následující produkty:</h3>
          <Products
            ammounts={sotredData.ammounts}
            discountCodes={sotredData.discountCodes}
            tables={sotredData.priceList.tables}
          />
        </div>
        {sotredData.discountCodes.length > 0 ? (
          <>
            <br />
            <div>
              <h3>Aplikovali jste následující slevové kódy</h3>
              {sotredData.discountCodes.map((d) => (
                <p key={d.Code}>
                  {d.Code}: {d.Description}
                </p>
              ))}
            </div>
          </>
        ) : (
          <></>
        )}
        <br />
        {sotredData.selectedGiftId !== -1 ? (
          <>
            <div>
              <h3>K tomu dostanete jako dárek:</h3>
              <p>
                {sotredData.priceList.gifts[sotredData.selectedGiftId].name} (+
                {sotredData.priceList.gifts[sotredData.selectedGiftId].price} Kč)
              </p>
            </div>
            <br />
          </>
        ) : (
          <></>
        )}
        {sotredData.priceList.settings.showMoreGoods ? (
          <>
            <h3>Chcete k objednávce jiné produkty z lékárny, než jsou v ceníku?</h3>
            <Form.Control
              as="textarea"
              value={formState.moreProducts}
              onChange={(e) => setFormState({ ...formState, moreProducts: e.target.value })}
            />
            <br />
          </>
        ) : (
          <></>
        )}
        {/* <h3>Máte nějakou další poznámku k objednávce?</h3>
        <Form.Control
          as="textarea"
          value={formState.note}
          onChange={(e) => setFormState({ ...formState, note: e.target.value })}
        />
        <br /> */}
        <h3>Chcete poslat potvrzení objednávky na další email?</h3>
        {[...Array(formState.mails.length).keys()].map((i) => (
          <Form.Control
            key={i}
            onChange={(e) => {
              let newMails = [...formState.mails];
              newMails[i] = e.target.value;
              setFormState({ ...formState, mails: newMails });
            }}
            value={formState.mails[i]}
          />
        ))}
        <FontAwesomeIcon
          icon={faPlus}
          className="text-success pointer"
          onClick={() => setFormState({ ...formState, mails: [...formState.mails, ""] })}
        />
        <br />
        <br />
        <h3>Máte nějaké tipy na rozšíření produktů v online nabídce a další vylepšení služeb?</h3>
        <Form.Control
          as="textarea"
          value={formState.tips}
          onChange={(e) => setFormState({ ...formState, tips: e.target.value })}
        />
      </Col>
      <Col xs={12} lg={6}>
        {sotredData.priceList.settings.showCalendar ? (
          <>
            <Form.Check type="checkbox" onClick={(e) => setPersonalPickup(e.target.checked)} className="left" />
            <h4>{config.personalPickupTitle}</h4>
            {personalPickup ? (
              <>
                <span id="CustomTakeoverContainer">
                  <p className="text-justify">{config.personalPickupText}</p>
                  <hr />
                  <p className="text-justify">
                    Vyzvednout Zboží může pouze Objednavatel nebo osoba Objednavatelem písemně zmocněná, která se prokáže
                    identifikačním průkazem totožnosti. Zboží vyžadující specifickou teplotu během přepravy bude vydáno
                    Objednavateli pouze za předpokladu, že je schopen tyto podmínky dodržet. Není-li schopen Objednavatel
                    dodržet přepravní podmínky dle SPC nebo návodu na Zboží, nebo tuto podmínku nedoloží, nebude Zboží vydáno.
                  </p>
                  <p className="text-justify">
                    Transport vyzvednutých vakcín musí probíhat podle požadavků SÚKLu na správnou distribuční praxi. Pokud
                    nebudou tyto podmínky dodrženy, vedoucí distribučního centra může odmítnout objednané vakcíny vydat. Stejně
                    tak nemohou být vakcíny předány nikomu jinému než přímo lékaři nebo jím zmocněným osobám (předložení
                    občanského průkazu a razítko lékaře).
                  </p>
                </span>
              </>
            ) : (
              <>
                <Calendar
                  height={3}
                  selectedDays={selectedOrderDays}
                  updateSelectedDays={updateSelectedDays}
                  priceList={sotredData.priceList}
                />
                <br />
                <h3>Máte nějakou poznámku k datu dodání?</h3>
                <Form.Control
                  as="textarea"
                  value={formState.deliveryNote}
                  onChange={(e) => setFormState({ ...formState, deliveryNote: e.target.value })}
                />
              </>
            )}
          </>
        ) : (
          <>
            <div>
              <h3>Rychlá expedice</h3>
              <br />
              DNES OBJEDNÁTE:
              <br />
              90 % DODÁME DO 48 HODIN
              <br />
              7 % DODÁME DO 3 DNÍ ZBYLÁ
              <br />3 % DODÁME DLE DOMLUVY
            </div>
            <br />
            <h3>Máte nějakou poznámku k datu dodání?</h3>
            <Form.Control
              as="textarea"
              value={formState.deliveryNote}
              onChange={(e) => setFormState({ ...formState, deliveryNote: e.target.value })}
            />
          </>
        )}
        <h3>Celková cena objednávky: {getTotalPrice()} Kč</h3> <br />
        <Button onClick={order} variant="success" className="right">
          Objednat
        </Button>
        <Button variant="primary" onClick={() => history.goBack()} className="right">
          Upravit objednávku
        </Button>
      </Col>
    </Row>
  );
}
