import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import RegisterForm from "./UserInformationForm";
import { dayToNum } from "../../common";

const defaultState = {
  City: "",
  CompanyId: "",
  CompanyNumber: "",
  CompanyName: "",
  DelCity: "",
  DelCompanyName: "",
  DelHouseNum: "",
  DelStreet: "",
  DelZIP: "",
  Email: "",
  HouseNum: "",
  Name: "",
  PharId: "",
  Phone: "",
  PayTax: null,
  Street: "",
  VAT: "",
  ZIP: "",
  Pwd: "",
  OppeningHours: {
    Mo: { TimeFrom: "00:00", TimeTo: "00:00", use: true },
    Tu: { TimeFrom: "00:00", TimeTo: "00:00", use: true },
    We: { TimeFrom: "00:00", TimeTo: "00:00", use: true },
    Th: { TimeFrom: "00:00", TimeTo: "00:00", use: true },
    Fr: { TimeFrom: "00:00", TimeTo: "00:00", use: true },
  },
};

export function reformatOppeningHours(oppeningHours) {
  let res = [];
  Object.keys(oppeningHours).forEach((key) => {
    const act = oppeningHours[key];
    if (act.use) {
      res.push({ TimeFrom: act.TimeFrom, TimeTo: act.TimeTo, DayId: dayToNum(key) });
    }
  });
  return res;
}

export default function ({ history, checkLogin }) {
  const [formState, setFormState] = useState(defaultState);

  const changeProp = (propName, newVal) => {
    let newState = { ...formState };
    newState[propName] = newVal;
    setFormState(newState);
  };
  const changeOppeningHours = (day, type, newVal) => {
    let newOppeningHours = { ...formState.OppeningHours };
    newOppeningHours[day][type] = newVal;
    setFormState({ ...formState, OppeningHours: newOppeningHours });
  };
  const submitForm = () => {
    let formData = new FormData();

    formData.append("user", JSON.stringify({ ...formState, OppeningHours: reformatOppeningHours(formState.OppeningHours) }));
    fetch(`/api/users/create.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          checkLogin();
          history.push("/Home");
        } else {
          alert("Registrace se nezdařila. Zkuste to prosím později.");
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <Alert variant="danger" style={{ width: "80%", marginLeft: "10%" }}>
        Tato část našich stránek je určena výhradně pro lékaře a další odborníky ve smyslu zákona č. 40/1995 Sb., o regulaci
        reklamy, v platném znění, tedy pro osoby oprávněné předepisovat nebo vydávat humánní léčivé přípravky. Registrace do
        této části stránek je povolena výhradně provozovatelům zdravotnických zařízení a jejich zaměstnancům.
      </Alert>
      <RegisterForm
        changeOppeningHours={changeOppeningHours}
        changeProp={changeProp}
        formState={formState}
        onFormSubmit={submitForm}
        hideNewUserInfo={false}
        submitButtonText="Registrovat se"
      />
    </>
  );
}
