import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { separateThousands } from "../../common";

function getDeliveryDaysString(days) {
  let res = [];
  if (days.Mo) res.push("Po");
  if (days.Tu) res.push("Út");
  if (days.We) res.push("St");
  if (days.Th) res.push("Čt");
  if (days.Fr) res.push("Pá");
  return res.join(", ");
}

function formatValidDate(date) {
  const spl = date.split("-");
  return `${spl[2]}-${spl[1]}-${spl[0]}`;
}

function PriceListInfo({ priceList }) {
  return (
    <>
      {priceList.id && (
        <>
          <h2 id="priceListName">
            {priceList.name} (platnost od {formatValidDate(priceList.settings.validFrom)})
          </h2>
          <Row>
            <Col xs={12} md={4}>
              {priceList.settings.minAmmount >= 0 && (
                <Card className="pharCard">
                  <Card.Header>
                    Minimální odběr: {separateThousands(priceList.settings.minAmmount)} Kč
                    <br />
                    {priceList.settings.minAmmountPersonal >= 0 ? (
                      <>Při osobním vyzvednutí: {separateThousands(priceList.settings.minAmmountPersonal)} Kč</>
                    ) : (
                      <br />
                    )}
                  </Card.Header>
                </Card>
              )}
            </Col>
            <Col xs={12} md={4}>
              {getDeliveryDaysString(priceList.settings.DeliveryDays) && (
                <Card className="pharCard">
                  <Card.Header>
                    Rozvozové dny:
                    <br />
                    {getDeliveryDaysString(priceList.settings.DeliveryDays)}&nbsp;
                  </Card.Header>
                </Card>
              )}
            </Col>
            <Col xs={12} md={4}>
              {getDeliveryDaysString(priceList.settings.PreparedDeliveryDays).length > 0 && (
                <Card className="pharCard">
                  <Card.Header>
                    Připravované rozvozové dny:
                    <br />
                    {getDeliveryDaysString(priceList.settings.PreparedDeliveryDays)}
                    &nbsp;
                  </Card.Header>
                </Card>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default PriceListInfo;
