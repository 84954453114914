import React, { useState } from "react";
import { Form, Row, Col, Card, Button, FormControl, Table } from "react-bootstrap";
import { numToDayCz, dayToNum } from "../../common";

export default function ({ formState, changeProp, changeOppeningHours, hideNewUserInfo, onFormSubmit, submitButtonText }) {
  const [showPwd, setShowPwd] = useState(false);
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit();
      }}
    >
      <Row>
        <Col md={6} xs={12}>
          <Card>
            <Card.Header>Osobní údaje</Card.Header>
            <Card.Body>
              <Row>
                <Col md={3} xs={12}>
                  Jméno
                </Col>
                <Col md={9} xs={12}>
                  <FormControl
                    type="text"
                    placeholder="Jméno a příjmení"
                    value={formState.Name}
                    onChange={(e) => changeProp("Name", e.target.value)}
                    required
                  />
                </Col>
              </Row>
              {!hideNewUserInfo && (
                <>
                  <Row>
                    <Col md={3} xs={12}>
                      Email
                    </Col>
                    <Col md={9} xs={12}>
                      <FormControl
                        type="text"
                        placeholder="Email"
                        pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                        title="Požadovaný formát: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                        value={formState.Email}
                        onChange={(e) => changeProp("Email", e.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} xs={12}>
                      Heslo
                    </Col>
                    <Col md={9} xs={12}>
                      <FormControl
                        type={showPwd ? "text" : "password"}
                        placeholder="Heslo"
                        required
                        value={formState.Pwd}
                        onChange={(e) => changeProp("Pwd", e.target.value)}
                      />
                      <p className="text-primary right pointer" onClick={(e) => setShowPwd(!showPwd)}>
                        Zobrazit heslo
                      </p>
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>Ordinační doba</Card.Header>
            <Card.Body>
              <Table>
                <thead>
                  <tr>
                    <th>Den v týdnu</th>
                    <th>Od</th>
                    <th>Do</th>
                    <th>V tento den neordinuji</th>
                  </tr>
                </thead>
                <tbody>
                  {["Mo", "Tu", "We", "Th", "Fr"].map((day) => (
                    <tr key={day}>
                      <th>{numToDayCz(dayToNum(day))}</th>
                      <td>
                        <FormControl
                          type="text"
                          pattern="^[0-9]{2}:[0-9]{2}$"
                          value={formState.OppeningHours[day].TimeFrom}
                          onChange={(e) => changeOppeningHours(day, "TimeFrom", e.target.value)}
                          title="Požadovaný formát: 00:00"
                          disabled={!formState.OppeningHours[day].use}
                          required
                        />
                      </td>
                      <td>
                        <FormControl
                          type="text"
                          value={formState.OppeningHours[day].TimeTo}
                          onChange={(e) => changeOppeningHours(day, "TimeTo", e.target.value)}
                          pattern="^[0-9]{2}:[0-9]{2}$"
                          title="Požadovaný formát: 00:00"
                          disabled={!formState.OppeningHours[day].use}
                          required
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={!formState.OppeningHours[day].use}
                          onClick={(e) => changeOppeningHours(day, "use", !formState.OppeningHours[day].use)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} xs={12}>
          <Card>
            <Card.Header>Fakturační adresa</Card.Header>
            <Card.Body>
              <Row>
                <Col md={3} xs={12}>
                  Název firmy
                </Col>
                <Col md={9} xs={12}>
                  <FormControl
                    type="text"
                    required
                    value={formState.CompanyName}
                    onChange={(e) => changeProp("CompanyName", e.target.value)}
                    placeholder="Název firmy"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3} xs={12}>
                  Ulice
                </Col>
                <Col md={5} xs={12}>
                  <FormControl
                    type="text"
                    required
                    value={formState.Street}
                    onChange={(e) => changeProp("Street", e.target.value)}
                    placeholder="Ulice"
                  />
                </Col>
                <Col md={1} xs={12}>
                  <label>ČP</label>
                </Col>
                <Col md={3} xs={12}>
                  <FormControl
                    type="text"
                    value={formState.HouseNum}
                    onChange={(e) => changeProp("HouseNum", e.target.value)}
                    required
                    placeholder="Číslo popisné"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3} xs={12}>
                  Město
                </Col>
                <Col md={5} xs={12}>
                  <FormControl
                    type="text"
                    required
                    value={formState.City}
                    onChange={(e) => changeProp("City", e.target.value)}
                    placeholder="Město"
                  />
                </Col>
                <Col md={1} xs={12}>
                  <label>PSČ</label>
                </Col>
                <Col md={3} xs={12}>
                  <FormControl
                    type="text"
                    value={formState.ZIP}
                    onChange={(e) => changeProp("ZIP", e.target.value)}
                    required
                    placeholder="PSČ"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3} xs={12}>
                  IČO
                </Col>
                <Col md={9} xs={12}>
                  <FormControl
                    type="text"
                    required
                    value={formState.CompanyId}
                    onChange={(e) => changeProp("CompanyId", e.target.value)}
                    placeholder="IČO"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3} xs={12}>
                  DIČ
                </Col>
                <Col md={9} xs={12}>
                  <FormControl
                    type="text"
                    value={formState.VAT}
                    onChange={(e) => changeProp("VAT", e.target.value)}
                    placeholder="DIČ"
                  />
                  Jsem plátce DPH{" "}
                  <input
                    type="radio"
                    checked={formState.PayTax === true}
                    onClick={(e) => {
                      changeProp("PayTax", true);
                    }}
                    name="PayTax"
                    required
                  />{" "}
                  Ano{" "}
                  <input
                    type="radio"
                    checked={formState.PayTax === false}
                    onClick={(e) => {
                      changeProp("PayTax", false);
                    }}
                    name="PayTax"
                    required
                  />{" "}
                  Ne
                </Col>
              </Row>
              <Row>
                <Col md={3} xs={12}>
                  IČZ
                </Col>
                <Col md={9} xs={12}>
                  <FormControl
                    type="text"
                    value={formState.CompanyNumber}
                    onChange={(e) => changeProp("CompanyNumber", e.target.value)}
                    required
                    pattern="[\d]+"
                    placeholder="IČZ"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3} xs={12}>
                  IČP
                </Col>
                <Col md={9} xs={12}>
                  <FormControl
                    type="text"
                    value={formState.PharId}
                    onChange={(e) => changeProp("PharId", e.target.value)}
                    required
                    pattern="[\d]+"
                    placeholder="IČP"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>Dodací adresa</Card.Header>
            <Card.Body>
              <Row>
                <Col xs={12} md={3}>
                  Název firmy
                </Col>
                <Col xs={12} md={9}>
                  <FormControl
                    type="text"
                    value={formState.DelCompanyName}
                    onChange={(e) => changeProp("DelCompanyName", e.target.value)}
                    placeholder="Název firmy"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={3}>
                  Ulice
                </Col>
                <Col xs={12} md={5}>
                  <FormControl
                    type="text"
                    value={formState.DelStreet}
                    onChange={(e) => changeProp("DelStreet", e.target.value)}
                    placeholder="Ulice"
                    required
                  />
                </Col>
                <Col xs={12} md={1}>
                  <label>ČP</label>
                </Col>
                <Col xs={12} md={3}>
                  <FormControl
                    type="text"
                    value={formState.DelHouseNum}
                    onChange={(e) => changeProp("DelHouseNum", e.target.value)}
                    placeholder="Číslo popisné"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={3}>
                  Město
                </Col>
                <Col xs={12} md={5}>
                  <FormControl
                    type="text"
                    value={formState.DelCity}
                    onChange={(e) => changeProp("DelCity", e.target.value)}
                    placeholder="Město"
                    required
                  />
                </Col>
                <Col xs={12} md={1}>
                  <label>PSČ</label>
                </Col>
                <Col xs={12} md={3}>
                  <FormControl
                    type="text"
                    value={formState.DelZIP}
                    onChange={(e) => changeProp("DelZIP", e.target.value)}
                    placeholder="PSČ"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={3}>
                  Telefon
                </Col>
                <Col xs={12} md={9}>
                  <FormControl
                    type="text"
                    value={formState.Phone}
                    onChange={(e) => changeProp("Phone", e.target.value)}
                    placeholder="Telefon"
                    required
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Button value="primary" type="submit" className="right">
            {submitButtonText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
