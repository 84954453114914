import React from "react";
import { Form, Row, Col, Card, Button, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import PwdRenewal from "./RenewPassword";
import AddView from "../../Components/AddView";

function LoginUser(e, email, pwd, history, checkLogin) {
  e.preventDefault();
  let formData = new FormData();
  formData.append("email", email);
  formData.append("pwd", pwd);
  fetch(`/api/users/login.php`, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
        checkLogin();
        history.push("/Home");
      } else {
        alert("Přihlášení se nezdařilo. Zkontolujte své uživatelské jméno a heslo.");
      }
    })
    .catch(console.log);
}

export default function ({ history, checkLogin }) {
  let email = "";
  let pwd = "";
  return (
    <>
      <Form onSubmit={(e) => LoginUser(e, email, pwd, history, checkLogin)}>
        <Row className="justify-content-md-center">
          <Col xs={12} md={8} lg={6}>
            <Card>
              <Card.Header>Přihlásit se</Card.Header>
              <Card.Body>
                <Row>
                  <Col xs={12} md={3} lg={2}>
                    <label>Email</label>
                  </Col>
                  <Col xs={12} md={9} lg={4}>
                    <FormControl onChange={(e) => (email = e.target.value)} type="text" placeholder="Email" required />
                  </Col>
                  <Col xs={12} md={3} lg={2}>
                    <label>Heslo</label>
                  </Col>
                  <Col xs={12} md={9} lg={4}>
                    <Form.Control onChange={(e) => (pwd = e.target.value)} type="password" placeholder="Heslo" required />
                  </Col>
                </Row>
                <PwdRenewal />
                <br />
                <br />
                <Button type="submit" className="right">
                  Přihlásit se
                </Button>
              </Card.Body>
            </Card>
            <Link to="/Register" className="left btn btn-primary">
              Registrovat
            </Link>
            <p className="left" style={{ marginTop: "15px", marginLeft: "10px" }}>
              Nemáte u nás účet? Zaregistrujte se zde
            </p>
          </Col>
        </Row>
      </Form>
      <AddView src="/img/index.png" />
    </>
  );
}
