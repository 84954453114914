import React from "react";
import { Card, Col, Row, FormControl, ButtonGroup, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

export default function ({ settings, name, onSettingsChange, onNameChange, images }) {
  const deliveryDaysChange = (day) => {
    let newDelDays = { ...settings.DeliveryDays };
    newDelDays[day] = !settings.DeliveryDays[day];
    onSettingsChange("DeliveryDays", newDelDays);
  };
  const prepDeliveryDaysChange = (day) => {
    let newDelDays = { ...settings.PreparedDeliveryDays };
    newDelDays[day] = !settings.PreparedDeliveryDays[day];
    onSettingsChange("PreparedDeliveryDays", newDelDays);
  };
  const prepDeliveryDaysSync = () => {
    let newDelDays = { ...settings.PreparedDeliveryDays };
    ["Mo", "Tu", "We", "Th", "Fr"].forEach((day) => {
      newDelDays[day] = !settings.DeliveryDays[day];
    });
    onSettingsChange("PreparedDeliveryDays", newDelDays);
  };

  return (
    <Card>
      <Card.Header>Nastavení ceníku</Card.Header>
      <Card.Body>
        <Row className="align-items-center">
          <Col xs={12} md={3}>
            Název
          </Col>
          <Col xs={12} md={9}>
            <FormControl type="text" value={name} onChange={(e) => onNameChange(e.target.value)} />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={12} md={3}>
            Platnost od
          </Col>
          <Col xs={12} md={9}>
            <FormControl
              type="date"
              value={settings.validFrom}
              onChange={(e) => onSettingsChange("validFrom", e.target.value)}
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={12} md={3}>
            Minimální odběr
          </Col>
          <Col xs={12} md={9}>
            <FormControl
              type="number"
              value={settings.minAmmount}
              onChange={(e) => onSettingsChange("minAmmount", parseInt(e.target.value))}
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={12} md={3}>
            Minimální odběr osobní převzetí
          </Col>
          <Col xs={12} md={9}>
            <FormControl
              type="number"
              value={settings.minAmmountPersonal}
              onChange={(e) => onSettingsChange("minAmmountPersonal", parseInt(e.target.value))}
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={12} md={3}>
            Reklama
          </Col>
          <Col xs={12} md={9}>
            <FormControl as="select" value={settings.add} onChange={(e) => onSettingsChange("add", e.target.value)}>
              <option value={null}></option>
              {images.map((img) => (
                <option key={img} value={img}>
                  {img}
                </option>
              ))}
            </FormControl>
          </Col>
        </Row>
        {settings.add && (
          <Row>
            <Col xs={12} md={3}>
              Odkaz reklamy
            </Col>
            <Col xs={12} md={9}>
              <FormControl value={settings.addLink || ""} onChange={(e) => onSettingsChange("addLink", e.target.value)} />
            </Col>
          </Row>
        )}
        <Row className="align-items-center">
          <Col xs={12} md={3}>
            Zobrazit harmonogram
          </Col>
          <Col xs={12} md={9}>
            <input
              type="checkbox"
              checked={settings.showCalendar}
              onChange={(e) => onSettingsChange("showCalendar", !settings.showCalendar)}
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={12} md={3}>
            Další zboží
          </Col>
          <Col xs={12} md={9}>
            <input
              type="checkbox"
              checked={settings.showMoreGoods}
              onChange={(e) => onSettingsChange("showMoreGoods", !settings.showMoreGoods)}
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={12} md={3}>
            Slevové kódy
          </Col>
          <Col xs={12} md={9}>
            <input
              type="checkbox"
              checked={settings.useDiscountCode}
              onChange={(e) => onSettingsChange("useDiscountCode", !settings.useDiscountCode)}
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={12} md={3}>
            Reklamační list
          </Col>
          <Col xs={12} md={9}>
            <input
              type="checkbox"
              checked={settings.RefundList}
              onChange={(e) => onSettingsChange("RefundList", !settings.RefundList)}
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={12} md={3}>
            Rozvážíme v
          </Col>
          <Col xs={12} md={9}>
            <ButtonGroup>
              <Button variant={settings.DeliveryDays.Mo ? "primary" : "secondary"} onClick={(e) => deliveryDaysChange("Mo")}>
                Po
              </Button>
              <Button variant={settings.DeliveryDays.Tu ? "primary" : "secondary"} onClick={(e) => deliveryDaysChange("Tu")}>
                Út
              </Button>
              <Button variant={settings.DeliveryDays.We ? "primary" : "secondary"} onClick={(e) => deliveryDaysChange("We")}>
                St
              </Button>
              <Button variant={settings.DeliveryDays.Th ? "primary" : "secondary"} onClick={(e) => deliveryDaysChange("Th")}>
                Čt
              </Button>
              <Button variant={settings.DeliveryDays.Fr ? "primary" : "secondary"} onClick={(e) => deliveryDaysChange("Fr")}>
                Pá
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} md={3}>
            Připravované rozvozové dny
          </Col>
          <Col xs={12} md={9}>
            <ButtonGroup>
              <Button
                variant={settings.PreparedDeliveryDays.Mo ? "primary" : "secondary"}
                onClick={(e) => prepDeliveryDaysChange("Mo")}
              >
                Po
              </Button>
              <Button
                variant={settings.PreparedDeliveryDays.Tu ? "primary" : "secondary"}
                onClick={(e) => prepDeliveryDaysChange("Tu")}
              >
                Út
              </Button>
              <Button
                variant={settings.PreparedDeliveryDays.We ? "primary" : "secondary"}
                onClick={(e) => prepDeliveryDaysChange("We")}
              >
                St
              </Button>
              <Button
                variant={settings.PreparedDeliveryDays.Th ? "primary" : "secondary"}
                onClick={(e) => prepDeliveryDaysChange("Th")}
              >
                Čt
              </Button>
              <Button
                variant={settings.PreparedDeliveryDays.Fr ? "primary" : "secondary"}
                onClick={(e) => prepDeliveryDaysChange("Fr")}
              >
                Pá
              </Button>
            </ButtonGroup>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>Synchronizovat s "Rozvážíme v"</Tooltip>}>
              <FontAwesomeIcon
                className="pointer text-info"
                style={{ marginLeft: "8px" }}
                icon={faSync}
                onClick={prepDeliveryDaysSync}
              />
            </OverlayTrigger>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
