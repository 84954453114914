import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import PriceTableCreate from "./CretePriceTableModal";
import PriceTableEditor from "./TableEditor";
import GiftAdder from "./NewGift";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import SettingsEditor from "./SettingsEditor";
import { Prompt } from "react-router-dom";

const emptyPriceList = {
  name: "",
  id: -1,
  tables: [],
  gifts: [],
  settings: {
    validFrom: "",
    minAmmount: 0,
    minAmmountPersonal: 0,
    add: null,
    showCalendar: true,
    showMoreGoods: true,
    RefundList: false,
    DeliveryDays: {
      Mo: true,
      Tu: true,
      We: true,
      Th: true,
      Fr: true,
    },
    PreparedDeliveryDays: {
      Mo: false,
      Tu: false,
      We: false,
      Th: false,
      Fr: false,
    },
  },
};

function createNewEmptyTable(name, format) {
  return {
    data: [],
    name,
    format,
    settings: {
      hide: false,
      add: null,
      minAmmount: 0,
    },
  };
}

export default function PriceLists({ match }) {
  const [priceList, setPriceListCallBack] = useState(emptyPriceList);
  const [images, setImages] = useState([]);
  const [changes, setChanges] = useState(false);

  const setPriceList = (data) => {
    setChanges(true);
    setPriceListCallBack(data);
  };

  useEffect(() => {
    fetch(`/api/priceLists/get.php?id=${match.params.id}`)
      .then((res) => res.json())
      .then((data) => {
        setPriceListCallBack(data);
      })
      .catch(console.log);
    fetch("/api/administration/getImages.php")
      .then((res) => res.json())
      .then((data) => setImages(data))
      .catch(console.log);
  }, [match.params.id]);

  const createPriceTable = (name, format) => {
    setPriceList({ ...priceList, tables: [...priceList.tables, createNewEmptyTable(name, format)] });
  };

  const savePriceList = (priceList) => {
    let formData = new FormData();
    formData.append("priceList", JSON.stringify(priceList));
    formData.append("id", priceList.id);
    fetch(`/api/priceLists/update.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          setChanges(false);
          alert("Změny byly uloženy");
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <Prompt when={changes} message="Jste si jistí, že chcete odejít? Všechny neuložené změny budou smazány." />
      <Row className="justify-content-md-center">
        <Col xs={12} lg={10}>
          <Card>
            <Card.Header>Tabulky ceníku</Card.Header>
            <Card.Body>
              {priceList.tables.map((t, i) => (
                <PriceTableEditor
                  key={i}
                  onChange={(newTable) => {
                    let newTables = [...priceList.tables];
                    newTables[i] = newTable;
                    setPriceList({ ...priceList, tables: newTables });
                  }}
                  onDelete={() => {
                    let newTables = [...priceList.tables];
                    newTables.splice(i, 1);
                    setPriceList({ ...priceList, tables: newTables });
                  }}
                  table={t}
                  images={images}
                />
              ))}
            </Card.Body>
          </Card>
          <PriceTableCreate onCreate={createPriceTable} />
        </Col>
      </Row>
      <br />
      <br />
      <Row className="justify-content-md-center">
        <Col xs={12} md={6} lg={5}>
          <SettingsEditor
            settings={priceList.settings}
            name={priceList.name}
            onNameChange={(newName) => setPriceList({ ...priceList, name: newName })}
            onSettingsChange={(prop, newVal) => {
              let newSett = { ...priceList.settings };
              newSett[prop] = newVal;
              setPriceList({ ...priceList, settings: newSett });
            }}
            images={images}
          />
        </Col>
        <Col xs={12} md={6} lg={5}>
          <Card>
            <Card.Header>Dárky</Card.Header>
            <Card.Body>
              {priceList.gifts.map((g, i) => (
                <Row key={i}>
                  <Col xs={12}>
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="text-danger pointer"
                      onClick={() => {
                        let newGifts = [...priceList.gifts];
                        newGifts.splice(i, 1);
                        setPriceList({ ...priceList, gifts: newGifts });
                      }}
                    />{" "}
                    {g.name}: od {g.minAmmount} Kč (cena: {g.price} Kč)
                  </Col>
                </Row>
              ))}
            </Card.Body>
          </Card>
          <GiftAdder
            onCreate={(info) =>
              setPriceList({ ...priceList, gifts: [...priceList.gifts, info].sort((a, b) => a.minAmmount - b.minAmmount) })
            }
          />
        </Col>
      </Row>
      <Button
        variant="primary"
        className="right"
        style={{ position: "sticky", bottom: "32px", right: "32px" }}
        onClick={() => savePriceList(priceList)}
      >
        Uložit změny
      </Button>
    </>
  );
}
