import React from "react";
import { Row, Col } from "react-bootstrap";
import { separateThousands } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faCircle } from "@fortawesome/free-solid-svg-icons";

function Gifts({ gifts, selectedGift, totalPrice, selectGift }) {
  return (
    <div id="giftsContainer">
      {gifts.length > 0 ? (
        <h3>Vyberte si dárek navíc</h3>
      ) : (
        <>
          <br />
          <br />
        </>
      )}
      {gifts.map((g, i) => (
        <Row
          key={i}
          onClick={parseInt(g.minAmmount) <= totalPrice ? () => selectGift(parseInt(i)) : () => {}}
          className={parseInt(g.minAmmount) <= totalPrice ? "pointer" : ""}
        >
          <Col>
            {parseInt(g.minAmmount) <= totalPrice ? (
              selectedGift === parseInt(i) ? (
                <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
              ) : (
                <FontAwesomeIcon icon={faCircle} className="text-secondary" />
              )
            ) : (
              <FontAwesomeIcon className="text-danger" icon={faTimesCircle} />
            )}
            &nbsp;<b>{g.name}: </b> Při nákupu od {separateThousands(g.minAmmount)} Kč za cenu {separateThousands(g.price)} Kč{" "}
            (zbývá {separateThousands(Math.max(0, parseInt(g.minAmmount) - totalPrice))} Kč)
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default Gifts;
