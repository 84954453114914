import React, { useState, useEffect } from "react";
import EditForm from "../Register/UserInformationForm";
import { Row, Card, FormControl, Col, Button, Form } from "react-bootstrap";
import { reformatOppeningHours } from "../Register/Register";

const defaultState = {
  City: "",
  CompanyId: "",
  CompanyNumber: "",
  CompanyName: "",
  DelCity: "",
  DelCompanyName: "",
  DelHouseNum: "",
  DelStreet: "",
  DelZIP: "",
  Email: "",
  HouseNum: "",
  Name: "",
  PharId: "",
  Phone: "",
  PayTax: null,
  Street: "",
  VAT: "",
  ZIP: "",
  Pwd: "",
  OppeningHours: {
    Mo: { TimeFrom: "00:00", TimeTo: "23:59", use: true },
    Tu: { TimeFrom: "00:00", TimeTo: "23:59", use: true },
    We: { TimeFrom: "00:00", TimeTo: "23:59", use: true },
    Th: { TimeFrom: "00:00", TimeTo: "23:59", use: true },
    Fr: { TimeFrom: "00:00", TimeTo: "23:59", use: true },
  },
};

function reformatUserData(data) {
  data.PayTax = data.PayTax === 1;
  let newOppeningHours = {
    Mo: { TimeFrom: "00:00", TimeTo: "23:59", use: false },
    Tu: { TimeFrom: "00:00", TimeTo: "23:59", use: false },
    We: { TimeFrom: "00:00", TimeTo: "23:59", use: false },
    Th: { TimeFrom: "00:00", TimeTo: "23:59", use: false },
    Fr: { TimeFrom: "00:00", TimeTo: "23:59", use: false },
    ...data.OppeningHours,
  };
  Object.keys(newOppeningHours).forEach((key) => {
    if (newOppeningHours[key].use === undefined) newOppeningHours[key].use = true;
  });
  data.OppeningHours = newOppeningHours;
  return data;
}

export default function () {
  const [formState, setFormState] = useState(defaultState);
  const [changePwdState, setChangePwdState] = useState({ new: "", old: "", show: false });

  const changeProp = (propName, newVal) => {
    let newState = { ...formState };
    newState[propName] = newVal;
    setFormState(newState);
  };
  const changeOppeningHours = (day, type, newVal) => {
    let newOppeningHours = { ...formState.OppeningHours };
    newOppeningHours[day][type] = newVal;
    setFormState({ ...formState, OppeningHours: newOppeningHours });
  };
  const submitForm = () => {
    let formData = new FormData();
    formData.append("user", JSON.stringify({ ...formState, OppeningHours: reformatOppeningHours(formState.OppeningHours) }));
    fetch(`/api/users/update.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          alert("Profil byl úspěšně změněn");
        } else {
          alert("Změna profilu se nezdařila. Zkuste to prosím později.");
        }
      })
      .catch(console.log);
  };

  const changePwd = () => {
    let formData = new FormData();
    formData.append("oldPwd", changePwdState.old);
    formData.append("newPwd", changePwdState.new);
    fetch(`/api/users/changePwd.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          alert("Heslo bylo úspěšně změněno");
          setChangePwdState({ new: "", old: "", show: false });
        } else {
          alert("Špatné heslo");
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    fetch("/api/users/get.php")
      .then((res) => res.json())
      .then((data) => {
        setFormState(reformatUserData(data));
      })
      .catch(console.log);
  }, []);

  return (
    <>
      <h3>Změna osobních údajů</h3>
      <br />
      <EditForm
        formState={formState}
        hideNewUserInfo={true}
        changeOppeningHours={changeOppeningHours}
        changeProp={changeProp}
        onFormSubmit={submitForm}
        submitButtonText="Uložit"
      />
      <h3>Změna hesla</h3>
      <br />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          changePwd();
        }}
      >
        <Row>
          <Col xs={12} md={6}>
            <Card>
              <Card.Header>Změnit heslo</Card.Header>
              <Card.Body>
                <Row>
                  <Col xs={12} md={2}>
                    Staré heslo
                  </Col>
                  <Col xs={12} md={4}>
                    <FormControl
                      type={changePwdState.show ? "text" : "password"}
                      placeholder="Staré heslo"
                      value={changePwdState.old}
                      onChange={(e) => setChangePwdState({ ...changePwdState, old: e.target.value })}
                      required
                    />
                  </Col>
                  <Col xs={12} md={2}>
                    Nové heslo
                  </Col>
                  <Col xs={12} md={4}>
                    <FormControl
                      type={changePwdState.show ? "text" : "password"}
                      placeholder="Nové heslo"
                      value={changePwdState.new}
                      onChange={(e) => setChangePwdState({ ...changePwdState, new: e.target.value })}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <p
                      className="right pointer text-primary"
                      onClick={(e) => setChangePwdState({ ...changePwdState, show: !changePwdState.show })}
                    >
                      Zobrazit heslo
                    </p>
                  </Col>
                </Row>
                <Button className="right" type="submit">
                  Uložit
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
}
